
import React, { useEffect, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { APP_BASEURL } from "../../agora.config";
import { DyteMeeting } from '@dytesdk/react-ui-kit';
import { DyteProvider, useDyteClient, useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';

const Met=()=>{
  const { meeting } = useDyteMeeting();
  useEffect(() => {
    meeting.joinRoom();
  }, [meeting]);
  return <DyteMeeting meeting={meeting} />;

}

const Meeting = (props) => {
  const [meeting, initMeeting] = useDyteClient();
  let history = useHistory();
  const[roomName, setRoomName]=useState();
  const[token, setToken]=useState();
  const[meetingId, setMeetingId]=useState();
  const[load, setLoad]=useState(false)
  const [user, setUser]=useState()
 useEffect(()=>{
  let user = props?.location?.state?.user
  let key = props?.location?.state?.appointmentData?.appointment_n_key
  if(user&&user ==="doctor"){
   handleCreateRoomClick(key)
  }
  if(user&&user ==="patient"){
    handlePatientRoom(key)
  }
 },[])
 useEffect(() => {
  if(token&&roomName){
  initMeeting({
    authToken: token,
    defaults: {
      audio: false,
      video: false,
    },
  });
}
}, [token,roomName]);

useEffect(() => {
  if (!meeting) return;
  
  const onRoomJoined = () => {
    console.log('You have joined the room');
  };
  
  const onRoomLeft = (left) => {
    if(left?.state == 'ended' &&props?.location?.state?.user === "doctor"){
      overallStaus()
    }
    else window.location.href="/thanks"
    console.log('You have left the room',left);
  };
  
  const onParticipantJoined = (paricipant) => {
    console.log(`${paricipant.name} has joined`);
    updateArrival()
  };
  
  meeting?.self?.addListener('roomJoined', onRoomJoined);
  meeting?.self?.addListener('roomLeft', onRoomLeft);
  meeting?.participants?.joined?.addListener('participantJoined', onParticipantJoined);
  
  return () => {
    meeting?.self?.removeListener('roomJoined', onRoomJoined);
    meeting?.self?.removeListener('roomLeft', onRoomLeft);
    meeting?.participants?.joined?.removeListener('participantJoined', onParticipantJoined);
  }
}, [meeting]);

 const headers = {
  "Authorization" : "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf",
  'Content-Type': 'application/json'
};
 const handleCreateRoomClick=async(key)=>{
   const parameter={
    "appointment_n_key": key,
    "org_n_key" :"ORGID-1"
   }
  await axios.post( APP_BASEURL + 'api/joinroomtherapist/', parameter, {headers}).then((res)=>{
    console.log(res)
    setRoomName(res.data.room_name)
    setToken(res.data.auth_token)
    setLoad(true)
    updateDoctorArrival()
  })
  .catch((err)=>console.log(err))
 }
 const handlePatientRoom=async(key)=>{
  const parameter={
   "appointment_n_key":key,
   "org_n_key" :"ORGID-1"
  }
 await axios.post( APP_BASEURL + 'api/joinroompatient/', parameter, {headers}).then((res)=>{
   console.log(res)
   setRoomName(res.data.room_name)
   setToken(res.data.auth_token)
   setLoad(true)
   updatePatitentArrival()
 })
 .catch((err)=>console.log(err))
}
const updatePatitentArrival=async()=>{
  var Appointment_key=props.location.state.appointmentData.appointment_n_key
  axios.get(APP_BASEURL +`api/caremeapp/appointment_crud/${Appointment_key}/`, { headers })
  .then((res)=>{
    if(res.data.status_check == false){
  const parameter ={ 'overall_status': 'Arrived'}
  axios.put(APP_BASEURL +`api/caremeapp/appointment_crud/${Appointment_key}/`,parameter, {headers}).then((res)=>{
  }).catch((err)=>console.log(err))
  }
  })
  }
  const updateDoctorArrival=async()=>{
    var Appointment_key=props.location.state.appointmentData.appointment_n_key
    axios.get(APP_BASEURL +`api/caremeapp/appointment_crud/${Appointment_key}/`, { headers })
    .then((res)=>{
      if(res.data.status_check == false){
    const parameter ={ 'overall_status': 'Practioner_Arrived'}
    axios.put(APP_BASEURL +`api/caremeapp/appointment_crud/${Appointment_key}/`,parameter, {headers}).then((res)=>{
      console.log(res)
    }).catch((err)=>console.log(err))
    }
    })
    }
    const updateArrival=async()=>{
      var Appointment_key=props.location.state.appointmentData.appointment_n_key
      axios.get(APP_BASEURL +`api/caremeapp/appointment_crud/${Appointment_key}/`, { headers })
      .then((res)=>{
        if(res.data.status_check == false){
      const parameter ={ 'overall_status': 'With_Practioner'}
      axios.put(APP_BASEURL +`api/caremeapp/appointment_crud/${Appointment_key}/`,parameter, {headers}).then((res)=>{
        console.log(res)
      }).catch((err)=>console.log(err))
      }
      })
      }
    const overallStaus=async()=>{
      var Appointment_key=props.location.state.appointmentData.appointment_n_key
      const parameter ={ 'overall_status': 'Completed'}
      axios.put(APP_BASEURL +`api/caremeapp/appointment_crud/${Appointment_key}/`,parameter, {headers}).then((res)=>{
        console.log(res)
        window.location.href="/thanks"
      }).catch((err)=>console.log(err))
      }
   
    return (

       load&&<div>
        ( 
             <DyteProvider value={meeting}>
              <Met />
             </DyteProvider>
          )
         
        </div>
    );
};

export default Meeting;


  

  